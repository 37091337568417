#mainSlider {
    padding-top:30px;
}

#mainSlider.splide .splide__track .splide__list .splide__slide .splide__slide__container {
    height:400px;
    

    img {
        width: 100%;
        height: 100%;
        object-fit:cover;
        object-position:center;
    }

    .foreground{
        text-align:center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px 10%;
        max-width: 1140px;

        .msg * {
            display:inline-block;
            padding:15px;
            background-color: rgba(17, 17, 17, 0.6);
            backdrop-filter: blur(2px);
            color: #fff;

        }

        &>div>h2{
            font-size:30px;
        }

        .btns {
            text-align: center;
            .btn + .btn {
                margin-left:15px;
            }

            .btn {
                background-color: var(--primary-color);
                color: #fff;
                font-weight: 400;
                padding: 15px;
                border: none;
                cursor: pointer;
            }

            .btn:hover {
                background-color: var(--primary-color);
                color: #fff;
            }
        }
    }
}

#clientsSlider.splide .splide__track .splide__list .splide__slide .splide__slide__container {
    height:100px;
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
