:root {
  --primary-color: rgb(98, 153, 21);
  --condensed-font-family: BlinkMacSystemFont, Roboto, Ubuntu;
  --rounded-font-family: "Segoe UI", Helvetica, Arial, sans-serif, Roboto, Ubuntu, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --text-color: var(--text-gray);
  --text-gray: rgb(116, 116, 116);
  --primary-400: var(--primary-color);
  --primary-600: var(--primary-color)
  --gray-200: #eaecf0;
  --gray-300: #e5e5e5;
  --gray-400: #e4e4e4;
  --gray-600: #b1b1b1;
  --gray-700: #767676;
  --gray-800: #5f6367;
  --container-width: 1200px;
  --content-width: 895px;
  --menu-link-color-hover: var(--primary-color);
  --footer-link-color-hover: var(--primary-color);}

body {
    overflow-x: hidden;
    padding-top: 80px;
}

@media(min-width: 1250px) {
  .title {
    margin-block-start: 20px;
    margin-block-end: 20px
  }
}

.btn {
  font-size:20px;
  text-align:center;
  padding-top:5px;
  padding-bottom:5px;
  padding-right:30px;
  padding-left:30px;
  line-height:2;
  font-weight:700;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color:white;
  background-color: var(--primary-color);
  transition: color 1s, background-color 1s;
}


.btn.btn-primary {
  background-color: var(--primary-color);
  color:white;
}

.btn.btn-primary:hover {
  color:var(--primary-color);
  background-color: white;
}

hr {
  border-top: solid 3px var(--primary-color);
  opacity: 1;
}

h1,
.h1 {
  text-transform: none
}

@media(min-width: 1000px) {

  h1,
  .h1 {
    font-size: 40px
  }
}

h2,
.h2 {
  text-transform: none;
  margin-block-start: 15px;
  margin-block-end: 10px;
  color:rgb(51, 51, 51);
}

@media(min-width: 1000px) {

  h2,
  .h2 {
    font-size: 27px;
    font-weight:550;
  }
}

h3,
.h3 {
  line-height: 120%;
  margin-block-start: 0px;
  margin-block-end: 10px
}

@media(min-width: 1000px) {

  h3,
  .h3 {
    font-size: 20px
  }
}

h4,
.h4 {
  text-transform: none;
  letter-spacing: normal
}

h5,
.h5 {
  text-transform: none;
  letter-spacing: normal
}

h6,
.h6 {
  text-transform: none;
  letter-spacing: normal;
  font-weight: 600
}

a {
  font-weight: 400;
  color: var(--black);
  text-decoration: none
}

a:hover {
  color: var(--link-color)
}

li {
  margin-bottom: 0px
}

ul, ul ul, .content ul ul, .content ul {
  margin-top: 0px
}

.highlight pre {
  padding: 20px
}

p {
  margin-top: 0px
}

.header>.container {
  box-sizing: border-box;
  justify-content: space-evenly
}

.header .logo {
  max-width: 246px;
  max-height: 48px
}

@media(min-width: 1000px) {
  .header .logo {
    max-width: 246px;
    max-height: 48px
  }
}

.header {
  position: fixed;
  top: 0;
  box-shadow: 0 4px 2px -2px rgba(66,81,92,0.12);
}

.main-menu {
  box-sizing: border-box
}

@media(min-width: 1000px) {
  .main-menu {
    justify-content: space-evenly;
    margin-left: clamp(5px, 5vw, 10px)
  }
}

.main-menu__wrapper {
  box-sizing: border-box
}

@media(min-width: 1000px) {
  .main-menu__wrapper {
    align-items: center;
    justify-content: space-between
  }
}

.main-menu__wrapper a {
  color: #757d89;
  font-family: Nunito;
  border-top: solid var(--white)
}

.main-menu__wrapper a:hover {
  border-top: solid var(--primary-400);
  color: var(--primary-400)
}

.main-menu__wrapper li.active > a, .main-menu__wrapper li:has(ul li.active) > a {
  border-top: solid var(--primary-400);
  color: var(--primary-400)
}

.main-menu__wrapper li li.active > a {
  border-top: none;
}

.main-menu__wrapper .parent a {
  color: #757d89;
  border: none
}

.main-menu__wrapper .parent a:hover {
  color: var(--primary-400)
}

.main-menu__wrapper .child-item {
  margin-top: 5px;
  margin-bottom: 25px
}

.main-menu__wrapper .child-item:last-child {
  margin-bottom: 0
}

.main-menu__wrapper .child-item a {
  color: #757d89;
  font-weight: lighter;
}

.main-menu__wrapper .child-item a:before {
  /* Reusing the same image as the menu */
  content: url("data:image/svg+xml,%3Csvg width='15' height='6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.21 1.078l3.395 3 3.395-3' stroke='%23b1b1b1' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  display: inline-block;
  transform: rotate(-90deg) translate(3px,0px);
  transform-origin: 50% 50%;
}

.main-menu__wrapper ul.child-list {
  padding-left: 15px;
}

.main-menu .main-menu-search {
  display: flex;
  align-items: center
}

.main-menu .main-menu-search svg {
  position: absolute
}

.main-menu .main-menu-search svg:hover {
  fill: var(--primary-400)
}

@media(min-width: 1000px) {
  .main-menu .main-menu-search svg {
    margin-left: 5px
  }
}

@media(max-width: 999px) {
  .main-menu .main-menu-search svg {
    margin-top: 5px
  }
}

.main-menu .main-menu-search input {
  border: none;
  background-color: #f8f8f8;
  padding-left: 40px;
  padding-top: 9px;
  padding-bottom: 9px
}

.main-menu .main-menu-search input:focus {
  border: none;
  outline: none
}

@media(min-width: 1000px) {
  .main-menu .top-menu-item {
    margin-left: 20px
  }
}

@media(min-width: 1250px) {
  .main-menu .top-menu-item {
    margin-right: 13px;
    margin-left: 13px
  }
}

.main-menu .top-menu-item>a {
  padding-right: 0px;
  justify-content: space-evenly;
  text-transform: none
}

@media(min-width: 1000px) {
  .main-menu .sub-menu {
    min-width: 600px
  }
}

.main-menu .sub-menu__nav {
  box-sizing: border-box
}

.main-menu .menu-link {
	cursor: pointer !important;
}


.footer {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: var(--gray-800);
  text-align: center;
}

.content p+ul {
  margin-top: -14px
}
.content a {
  color: var(--primary-color);
}

.content .info-line a {
  color: var(--black);
}


.accordions {
  margin-bottom: 40px
}

.accordion__title {
  justify-content: flex-start
}

.accordion__title>h4 {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 5px;
  line-height: 16px;
  font-size: 10px;
  text-transform: none;
  color: var(--text-color);
  font-style: normal;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-inline-end: 5px
}

@media(min-width: 1000px) {
  .accordion__title>h4 {
    font-size: 13px;
    line-height: 15px
  }
}

.accordion__title:hover h4 {
  color: var(--primary-400)
}

.accordion__content {
  font-size: 13px;
  line-height: 20px
}

.accordion__content__active h4 {
  color: #629915
}

.accordion__content .accordion__content {
  margin-bottom: 5px
}

@media(min-width: 1000px) {
  .accordion__content .accordion__content {
    margin-bottom: 10px
  }
}

.accordion__content .accordion__content p {
  margin-bottom: 0px
}

.blog-item {
  margin-bottom: 35px
}

.blog-item:first-of-type {
  margin-top: 35px
}

@media(min-width: 1000px) {
  .blog-item {
    margin-bottom: 60px
  }
}

.blog-item__date{
    font-weight:400;
}
.blog-item__byline p {
  font-family: Nunito
}

.blog-item__summary {
  margin-top: 15px
}

.breadcrumb {
  margin-block-start: 70px;
  margin-block-end: 0;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 2.1px;
  text-transform: uppercase
}

@media(min-width: 1250px) {
  .breadcrumb {
    margin-block-start: 100px;
    margin-block-end: 0
  }
}

@media(min-width: 600px) {
  .breadcrumb {
    font-size: 16px
  }
}

.breadcrumb a {
  text-decoration: none;
  color: var(--gray-800)
}

.breadcrumb+.title,
.breadcrumb+h1,
.breadcrumb+.h1 {
  margin-block-start: 30px;
  margin-block-end: 50px
}

@media(min-width: 1250px) {

  .breadcrumb+.title,
  .breadcrumb+h1,
  .breadcrumb+.h1 {
    margin-block-start: 60px;
    margin-block-end: 80px
  }
}


/* register */
  #productContainer {
    padding-top:2em;
  }
  #productContainer h5 {
    margin-top:0px;
    margin-bottom:10px;
  }
  #productContainer ol {
    margin-top:5px;
  }
  #productContainer li {
    font-size:15px;
  }
  #productContainer h2 {
    margin-top:0px;
  }

/* list */
  #related-products-partiel {
    display:flex;
    justify-content:space-between;
    flex-wrap:wrap;
  }

  .related-product {
    flex: 0 0 45%;
    display:flex;
    flex-direction:column;
    padding:1em;
    margin:1em;
  }

  .border-frame {
    border: solid 0.8px #e4e4e4;
    padding:1em;
  }
/* index */
  #main-page-container{
    display:flex;
    flex-direction:column;
  }

  .row-element{
    margin:1em;
    display:flex;
    justify-content:space-between;
  }
/* shortcodes price */
  #price {
    color:var(--primary-color);
  }
/* shortcodes imageAndTextRow */
  .imageElement {
    vertical-align:top;
    margin-top:5px;
    width:100%;
    height:auto;
  }
  .textElement {
    flex: 2;
    margin-left:1em;
    margin-right:1em;
    text-align:top;
  }
/* shortcodes description */
  #description {
    padding:40px;
    margin:20px;
    border:solid 0.8px #e4e4e4;
  }
/* shortcodes checkoutBtn */
  #checkoutContainer {
    text-align:right;
  }
  #checkoutBtn {
    background-color:var(--primary-color);
    padding-left:30px;
    padding-right:30px;
    padding-top:15px;
    padding-bottom:15px;
    border-radius:3px;
    border:none;
    color:white;
    font-weight:bold;
    font-size:20px;
  }
/* shortcodes/registerBtn, shortcodes/buyOnAmazon, shortcodes/registerAdditionalBtn */
  div.actionContainer {
    padding-top:2em;
    width: 290px !important;
    float: right;
    border: 1px solid var(--primary-color);
    padding: 30px 10px;
    margin: 10px 10px;
    text-align: center;
    position: relative;
    width: auto;
  }
  div.actionContainer > p.note {
    font-size:10px;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 20px);
    margin: 0px;
  }

  div.actionContainer.inline {
    float: none;
    height: 290px !important;
    float: left;
  }

  div.actionContainer.inline .btn {
    position: absolute;
    bottom: 30px;
    left: 50px;
    right: 50px;
  }


/* shortcodes trainingBlock */
div.trainingBlock {
  margin-bottom: 60px;
  position: relative;

  box-shadow: 0 -1px 1px rgba(66,81,92,0.12),
                    0 2px 2px rgba(66,81,92,0.12),
                    0 4px 4px rgba(66,81,92,0.12),
                    0 8px 8px rgba(66,81,92,0.12),
                    0 16px 16px rgba(66,81,92,0.12);
}

div.trainingBlock .trainingBlock-content {
  padding: 30px 30px;
  padding-bottom: 60px;
  box-sizing: border-box;

}

div.trainingBlock .btn-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
}
div.trainingBlock .btn {
  display: inline-block;
}


/* main content information (title, breadcrumbs)*/
    #title-container{
        width:100%;
        background-color:#eaecf0;
    }
    #cont-test{
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    #title-container>div>h1{

    }

/* main pages layout */
    #title-container>div>h1{
        font-weight:500;
        color:rgb(51, 51, 51);
        font-size:30px;
    }
    .main-content{
        padding-top:2em;
        display:flex;
    }
/* breadcrumbs partial */
    #breadcrumbs{
        justify-self:center;
        align-self:center;
        font-size:10px;
    }
    #breadcrumbs>a{
        font-size:10px;
    }

/* sidebar */
  #sidebar{
    margin:1em;
    width:25%;
    height:auto;
    position:right;
    padding:10px;
}
/* partials/sidebar recentPost */
  .recent-posts{
    margin-bottom:40px;
    display:flex;
    flex-direction: column;
    align-items:start;
  }
  .recent-posts>h4{
    margin:5px;
    font-size:13px;
    font-weight:600;
  }
  .recent-post-list{
    /* list-style-position: inside; */
    list-style-image: url('/assets/caret-right.svg');
    padding:0px;
    margin:5px;    
  }
  
  .recentPostsList>a{
    font-size:13px;
    font-weight:400;
    /* display:inline; */
    color: var(--text-gray);
  }
  .recentPostsList>a:hover{
    color:var(--primary-400);
  }

  .recentPostsList {
    line-height:12px;
    padding-bottom:10px;
    padding-top:5px;
    border-bottom:solid 1px #eaecf0;
    margin-left:15px;
  }
/* partials/sidebar testimonials */
  .testimonial-container {
    background-color:#eaecf0;
    font-size:13px;
    font-weight:400;
    margin:none;
    padding:10px;
    margin-bottom:5px;
  }
  span {
    font-size:13px;
    line-height:13px;
}
/* partials/sidebar questions */
/* partials/product/relatedProduct */
#related-products-partiel {
  display:flex;
  justify-content:space-evenly;
  flex-wrap:wrap;
}

.related-product {
  flex: 0 0 45%;
  display:flex;
  flex-direction:column;
  padding:1em;
  margin:1em;
}

.border-frame {
  border: solid 0.8px #e4e4e4;
  padding:1em;
}
/* partials/product/productCategory */
  #cat-container {
    text-align:right;
  }
/* partials/blog/byline */
  .info-line{
    display:flex;
    justify-content:space-between;
    padding:10px;
    border-top:solid 1px #eaecf0;
    border-bottom:solid 1px #eaecf0;
  }
    .right-side-line>*{
        border-right:solid 1px #eaecf0;
        display:inline;
        justify-content:center;
        padding-right:10px;
        padding-left:10px;
    }
  blockquote{
    background-color:#eaecf0;
    margin-top:20px;
    margin-bottom:20px;
  }
/* partials/mainPage/videoThimbnail */
  #video-container{
    margin:1em;
  }

/* partials/mainPage/readingbox */
  #reading-box-container {
    display:flex;
    flex-direction:column;
  }
  .text-container{
    display:flex;
    flex-direction:column;
  }
  .reading-box {
    border-top:solid 3px var(--primary-color);
    padding:1em;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding-bottom: 50px;
  }
  .reading-box > a {
    flex:0 0 25%;
  }

#thumbnail-container{
  text-align:center;
}
.thumbnail-image{
  height:125px;
  width:96px;
}
.thumbnail-books {
  margin: 0px;
}


.other-links>li{
  text-align:start;
}
.other-links{
  justify-self:start;
  margin-left:1em;
}


/* certification page => table */
table {
    border-radius:2px;
    table-layout:fixed;
    border: none;
}
table th{
    border-radius: 2px;
    border-bottom: none;
    background-color: none;
}
table th,td{
    width:25%;
}
table th:last-child,
table td:last-child {
    border-top-right-radius: 2px;
}
table th:first-child,
table td:first-child {
    border-top-left-radius: 2px;
}
table tr td {
    border: none;
}
table td:not(:last-child),
table th:not(:last-child) {
    border-right:none;
}
table td,
table th {
    padding: 10px;
    text-align: left;
    overflow: hidden;
}

.content .dt-layout-cell {
   width: auto;
}

/* hide recaptcha v3 badge */
.grecaptcha-badge {
    visibility: hidden;
}

#response-output.error {
    padding: 5px;
    border: 2px solid red;
}

#response-output.success {
    padding: 5px;
    border: 2px solid green;
}

/* theme's card element updated style */
/* to have th hover effect */
.card{
    background:transparent;
    position:relative;
    border:none;
    box-shadow: none;
}
.card-side{
    transition: all 0.8s ease;
    position:absolute;
    top:0;
    left:0;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 40px 24px;
    border-radius: 10px;
    padding:1em;
    backface-visibility: hidden;
    text-align:center;
}

.card-side.back {
    transform:rotateY(-180deg);
    background-color: rgb(57, 124, 43);
}
.card-side.back h3,
.card-side.back p{
    color:white;
}
.card-side.front {
    background-color:white;
}
.card:hover .card-side.front{
    transform:rotateY(180deg);
}

.card:hover .card-side.back{
    transform:rotateY(0deg);
}

/* books content layout */

/* the button on books page that takes users to amazon */
    .amazon-btn{
        justify-self: center;
    }
    .errata-color{
        color:red;
    }

/* style for errata table */

tr {
    border-bottom:1px solid gray;
}

/* underlined text */

.underlined-text {
    text-decoration:underline;
    font-size:16px;
}

code {
  font-family: Consolas,"courier new";
  background-color: var(--gray-200);
  padding: 2px;
  font-size: 105%;
  padding: 5px 15px;
  border: solid 1px var(--primary-color)
}
